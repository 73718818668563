document.addEventListener('DOMContentLoaded', function() {
    var menuToggle = document.getElementById('menu-toggle');
    var menu = document.querySelector('.menu');

    function toggleMenu(e) {
        e.preventDefault();
        menu.classList.toggle('open');
    }

    function closeMenuIfClickedOutside(e) {
        if (!menu.contains(e.target) && !menuToggle.contains(e.target) && menu.classList.contains('open')) {
            menu.classList.remove('open');
        }
    }

    menuToggle.addEventListener('click', toggleMenu);

    document.addEventListener('click', closeMenuIfClickedOutside);

    menu.addEventListener('click', function(e) {
        e.stopPropagation();
    });
});

window.addEventListener('load', function() {
    // Primeiro, desvanece a animação de carregamento
    var loader = document.getElementById('loader');

    loader.style.opacity = 1;

    (function fadeOut() {
        if ((loader.style.opacity -= 0.1) < 0) {
            loader.style.display = 'none';
        } else {
            requestAnimationFrame(fadeOut);
        }
    })();

    // Depois, desvanece o DIV inteiro que cobre o site após um atraso
    var preloader = document.getElementById('preloader');
    
    setTimeout(function() {
        preloader.style.opacity = 1;

        (function fadeOutSlow() {
            if ((preloader.style.opacity -= 0.05) < 0) {
                preloader.style.display = 'none';
            } else {
                requestAnimationFrame(fadeOutSlow);
            }
        })();
    }, 300);
    
});


/**
 * resources slider
 */
const slider = document.querySelector('.slider');

function activate(e) {
    const items = document.querySelectorAll('.item');
    e.target.matches('.next') && slider.append(items[0])
    e.target.matches('.prev') && slider.prepend(items[items.length-1]);
}

document.addEventListener('click',activate,false);

/**
 * goal section bouncing blobs
 */
const MIN_SPEED = 0.5
const MAX_SPEED = 2

function randomNumber(min, max) {
  return Math.random() * (max - min) + min
}

class Blob {
    constructor(el, containerRect) {
        this.el = el;
        this.containerRect = containerRect;
        this.size = this.el.getBoundingClientRect().width;
        this.initialX = randomNumber(0, this.containerRect.width - this.size);
        this.initialY = randomNumber(0, this.containerRect.height - this.size);
        this.el.style.top = `${this.initialY}px`;
        this.el.style.left = `${this.initialX}px`;
        this.vx = randomNumber(MIN_SPEED, MAX_SPEED) * (Math.random() > 0.5 ? 1 : -1);
        this.vy = randomNumber(MIN_SPEED, MAX_SPEED) * (Math.random() > 0.5 ? 1 : -1);
        this.x = this.initialX;
        this.y = this.initialY;
    }

    update() {
        this.x += this.vx;
        this.y += this.vy;
        if (this.x >= this.containerRect.width - this.size) {
            this.x = this.containerRect.width - this.size;
            this.vx *= -1;
        }
        if (this.y >= this.containerRect.height - this.size) {
            this.y = this.containerRect.height - this.size;
            this.vy *= -1;
        }
        if (this.x <= this.containerRect.left) {
            this.x = this.containerRect.left;
            this.vx *= -1;
        }
        if (this.y <= this.containerRect.top) {
            this.y = this.containerRect.top;
            this.vy *= -1;
        }
    }

    move() {
        this.el.style.transform = `translate(${this.x}px, ${this.y}px)`;
    }
}

function initBlobs() {
    const headerSection = document.querySelector('header');
    var goalSection = document.querySelector('.goal');
    const containerRect = headerSection.getBoundingClientRect();
    const blobEls = goalSection.querySelectorAll('.bouncing-blob');
    const blobs = Array.from(blobEls).map((blobEl) => new Blob(blobEl, containerRect));

    function update() {
        requestAnimationFrame(update);
        
        blobs.forEach((blob) => {
            blob.update();
            blob.move();
        });
    }

    requestAnimationFrame(update);
}

initBlobs();

const resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
        initBlobs();
    }
});

var goalSection = document.querySelector('.goal');

resizeObserver.observe(goalSection);